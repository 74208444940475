body {
	/*font-family: 'Nunito Sans', sans-serif; */
	background-color: #2673ac;
	color: #fff;
}

a {
	color:#30AC26;
}

a:hover {
	color: #30AC27;
	text-decoration: underline;
}

.text-muted {
	color: #ccc !important;
}

nav .navbar-nav li .active  {
	color: #2673ac !important ;
}

nav .navbar-nav li .btn:visited {
	color: #fff !important;
}

nav .navbar-nav li a {
	color: #63bc46 !important ;
}

nav .navbar-nav li a:hover {
	text-decoration: underline;
}


.text-success {
	/*color: #63bc46 !important ; */
	color: #30AC26 !important ;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
	/*background-color: #2673ac !important;
	border-color: #2673ac !important; */
	background-color: #30AC26 !important;
	border-color: #30AC26 !important; 
	color: #fff !important;
}

.btn-outline-primary,
.btn-outline-primary:hover,
.btn-outline-primary:active,
.btn-outline-primary:visited {
	background-color: #fff !important;
	border-color: #30AC26 !important; 
	color: #2673ac !important;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited {
	background-color: #63bc46 !important;
	border-color: #63bc46 !important;
}

.carousel-item {
	height: 270px;
}

.carousel-item img {
	position: absolute;
	object-fit: cover;
	top: 0;
	left: 0;
	min-width: 100%;
}

.destinationLink {
	text-decoration: none;
	color: #555;
	font-weight: bold;
}

.text-blue {
	color: #2673ac;
}

.footer {
	background-color: #fff;
	color: #555;
}